
import { snakeCase, get, kebabCase, each } from "lodash";
import dayjs, { Dayjs } from "dayjs";
import { AxiosStatic } from "axios";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { notification } from "ant-design-vue";
import {
  defineComponent,
  reactive,
  ref,
  Ref,
  onMounted,
  inject,
  watch,
} from "vue";
import {
  IdType,
  LanguagesRegistration,
  RegistrationData,
  RuleObjectExt,
} from "../../types";
import { isValidPesel, getDateFromPesel } from "@hd2/common/src/utils";
import Tags from "@hd2/common/src/components/Tags.vue";
import PhoneNumberComponent from "@hd2/common/src/components/PhoneNumber.vue";
import { useForm } from "ant-design-vue/lib/form";
import { PhoneNumber } from "@hd2/common/types";
import type { SelectProps } from "ant-design-vue";

interface RegistrationModel {
  firstName: string;
  lastName: string;
  email: string;
  phone: PhoneNumber;
  termsAgreement: boolean;
  marketingAgreement: boolean;
  commercialAgreement: boolean;
  languageId: number;
  pesel: string;
  "document.number": string;
  "document.type": string;
  dateOfBirth: Dayjs | null;
  gender: string | null;
  identificationType: Set<IdType>;
}

interface FormItem {
  [key: string]: {
    type: string;
    visible: boolean;
    onChange?: Function;
    disabled?: boolean;
    data?: unknown;
    checboxValidation?: boolean;
  };
}

interface Form {
  general: FormItem;
  agreements: FormItem;
}

export const RegistrationComponent = defineComponent({
  components: {
    Tags,
    "phone-number": PhoneNumberComponent,
  },
  setup() {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const router = useRouter();

    const documentIdTypeOptions: Ref<SelectProps["options"]> = ref([]);
    const genderOptions: Ref<SelectProps["options"]> = ref([]);
    const languageOptions: Ref<SelectProps["options"]> = ref([]);

    const model: RegistrationModel = reactive({
      firstName: "",
      lastName: "",
      email: "",
      phone: {
        number: "",
        pattern: "",
        prefix: "",
      },
      termsAgreement: false,
      marketingAgreement: false,
      commercialAgreement: false,
      "document.number": "",
      "document.type": "",
      pesel: "",
      gender: null,
      languageId: 0,
      dateOfBirth: null,
      identificationType: new Set(["PESEL"]),
    });

    const loading: Ref<boolean> = ref(false);
    const rules: Record<string, Array<RuleObjectExt>> = {
      firstName: [
        {
          validator: (
            rule: RuleObjectExt,
            value: RegistrationModel["firstName"]
          ) => {
            return new Promise((resolve, reject) => {
              if (!value) {
                reject(t("REGISTRATION.VALIDATION.FIRST_NAME"));
              } else if (/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g.test(value)) {
                reject(t("REGISTRATION.VALIDATION.FIRST_NAME_NOT_VALID"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      lastName: [
        {
          validator: (
            rule: RuleObjectExt,
            value: RegistrationModel["lastName"]
          ) => {
            return new Promise((resolve, reject) => {
              if (!value) {
                reject(t("REGISTRATION.VALIDATION.LAST_NAME"));
              } else if (/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]/g.test(value)) {
                reject(t("REGISTRATION.VALIDATION.LAST_NAME_NOT_VALID"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      email: [
        {
          type: "email",
          required: true,
          trigger: "change",
          message: t("REGISTRATION.VALIDATION.EMAIL"),
        },
      ],
      phone: [
        {
          validator: (
            rule: RuleObjectExt,
            value: RegistrationModel["phone"]
          ) => {
            return new Promise((resolve, reject) => {
              if (!value.number) {
                reject(t("REGISTRATION.VALIDATION.PHONE_NUMBER_REQUIRED"));
              } else if (
                value.pattern ? !value.number.match(value.pattern) : null
              ) {
                reject(t("REGISTRATION.VALIDATION.PHONE_NUMBER_INVALID"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      pesel: [
        {
          validator: (
            rule: RuleObjectExt,
            value: RegistrationModel["pesel"]
          ) => {
            return new Promise((resolve, reject) => {
              if (
                (!value || !isValidPesel(value)) &&
                model.identificationType?.has("PESEL")
              ) {
                reject(t("REGISTRATION.VALIDATION.PESEL_VALID"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      "document.type": [
        {
          validator: (
            rule: RuleObjectExt,
            value: RegistrationModel["document.type"]
          ) => {
            return new Promise((resolve, reject) => {
              if (!value && model.identificationType?.has("OTHER")) {
                reject(t("REGISTRATION.VALIDATION.DOCUMENT"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      "document.number": [
        {
          validator: (
            rule: RuleObjectExt,
            value: RegistrationModel["document.number"]
          ) => {
            return new Promise((resolve, reject) => {
              if (!value && model.identificationType?.has("OTHER")) {
                reject(t("REGISTRATION.VALIDATION.DOCUMENT_NO"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      dateOfBirth: [
        {
          validator: (
            rule: RuleObjectExt,
            value: RegistrationModel["dateOfBirth"]
          ) => {
            return new Promise((resolve, reject) => {
              if (model.identificationType?.has("OTHER")) {
                if (!value) {
                  reject(t("REGISTRATION.VALIDATION.BIRTHDATE_REQUIRED"));
                }
              }
              resolve();
            });
          },
          trigger: "change",
        },
      ],
      gender: [
        {
          validator: (
            rule: RuleObjectExt,
            value: RegistrationModel["gender"]
          ) => {
            return new Promise((resolve, reject) => {
              if (!value && model.identificationType?.has("OTHER")) {
                reject(t("REGISTRATION.VALIDATION.GENDER"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
      termsAgreement: [
        {
          validator: (
            rule: RuleObjectExt,
            value: RegistrationModel["termsAgreement"]
          ) => {
            return new Promise((resolve, reject) => {
              if (!value) {
                document
                  .querySelector(".agreement-validation")
                  ?.classList.add("agreement-validation__error");
                reject(t("REGISTRATION.VALIDATION.TERMS"));
              } else {
                document
                  .querySelector(".agreement-validation")
                  ?.classList.remove("agreement-validation__error");
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const registrationForm: any = useForm(model, rules);
    const registrationFormTemplate = ref();
    const valid: Ref<boolean> = ref(false);

    const form: Form = reactive({
      general: {
        firstName: {
          type: "input",
          visible: true,
        },
        lastName: {
          type: "input",
          visible: true,
        },
        email: {
          type: "input",
          visible: true,
        },
        phone: {
          type: "phone",
          visible: true,
        },
        languageId: {
          type: "select",
          visible: true,
          data: languageOptions,
        },
        identificationType: {
          type: "tags",
          visible: true,
          data: [
            {
              id: "PESEL" as IdType,
              name: t("ORDER_PERSONAL.PATIENT.HAS_ID_YES"),
            },
            {
              id: "OTHER" as IdType,
              name: t("ORDER_PERSONAL.PATIENT.HAS_ID_NO"),
            },
          ],
          onChange: (
            identificationType: RegistrationModel["identificationType"]
          ) => {
            if (identificationType.has("PESEL")) {
              form.general["document.type"].visible = false;
              form.general["document.number"].visible = false;
              form.general.gender.visible = false;
              form.general.pesel.visible = true;
              form.general.dateOfBirth.disabled = true;
            } else if (identificationType.has("OTHER")) {
              form.general["document.type"].visible = true;
              form.general["document.number"].visible = true;
              form.general.gender.visible = true;
              form.general.pesel.visible = false;
              form.general.dateOfBirth.disabled = false;
            }

            const resetOptionalFields = {
              pesel: "",
              dateOfBirth: null,
              documentNumber: "",
              documentType: "",
              gender: null,
            };

            Object.assign(model, resetOptionalFields);
            registrationFormTemplate.value.clearValidate();
          },
        },

        pesel: {
          type: "input",
          visible: true,
          onChange: ({
            target: { value },
          }: {
            target: { value: NonNullable<RegistrationModel["pesel"]> };
          }) => {
            if (isValidPesel(value)) {
              model.dateOfBirth = dayjs(getDateFromPesel(value));
            }
          },
        },

        "document.type": {
          type: "select",
          visible: false,
          data: documentIdTypeOptions,
        },
        "document.number": {
          visible: false,
          type: "input",
        },
        dateOfBirth: {
          type: "datepicker",
          visible: true,
          disabled: true,
        },
        gender: {
          type: "select",
          data: genderOptions,
          visible: false,
        },
      },

      agreements: {
        termsAgreement: {
          type: "checkbox",
          visible: true,
          data: [
            t(`REGISTRATION.AGREEMENTS.TERMS_AGREEMENT1`),
            t(`REGISTRATION.AGREEMENTS.TERMS_AGREEMENT2`),
            t(`REGISTRATION.AGREEMENTS.TERMS_AGREEMENT3`),
            t(`REGISTRATION.AGREEMENTS.TERMS_AGREEMENT4`),
            t(`REGISTRATION.AGREEMENTS.TERMS_AGREEMENT5`),
          ],
          checboxValidation: true,
        },
        marketingAgreement: {
          type: "checkbox",
          visible: true,
          data: t(
            `REGISTRATION.AGREEMENTS.${snakeCase(
              "marketingAgreement"
            ).toUpperCase()}`
          ),
        },
        commercialAgreement: {
          type: "checkbox",
          visible: true,
          data: t(
            `REGISTRATION.AGREEMENTS.${snakeCase(
              "commercialAgreement"
            ).toUpperCase()}`
          ),
        },
      },
    });

    const getLanguagesTypes = async () => {
      try {
        const { data }: { data: LanguagesRegistration } = await http.get(
          "/patient-portal/api/dictionary/languages"
        );

        for (const key in data) {
          languageOptions.value?.push({
            value: data[key],
            label: t(`LANGUAGES.${key}`),
          });
        }
        model.languageId = data["PL"];
      } catch (e) {
        notification.open({
          message: t("ERROR.4983"),
          class: "error",
        });
      }
    };

    const getGenderTypes = async () => {
      try {
        const { data } = await http.get(
          "/patient-portal/api/dictionary/genders"
        );

        genderOptions.value = data.map((gender: string) => {
          return {
            value: gender,
            label: t(`GENDERS.${gender}`),
          };
        });
      } catch (e) {
        notification.open({
          message: t("ERROR.4983"),
          class: "error",
        });
      }
    };

    const getDocumentTypes = async () => {
      try {
        const { data } = await http.get(
          "/patient-portal/api/dictionary/document-types"
        );

        documentIdTypeOptions.value = data.map((document: string) => {
          return {
            value: document,
            label: t(`DOCUMENTS.${document}`),
          };
        });
      } catch (e) {
        notification.open({
          message: t("ERROR.4983"),
          class: "error",
        });
      }
    };

    const submit = async () => {
      loading.value = true;
      const payload: RegistrationData = {
        firstName: model.firstName,
        lastName: model.lastName,
        email: model.email,
        phone: {
          number: model.phone.number,
          prefix: model.phone.prefix,
        },
        termsAgreement: model.termsAgreement,
        marketingAgreement: model.marketingAgreement,
        commercialAgreement: model.commercialAgreement,
        languageId: model.languageId,
      };

      if (model.identificationType.has("OTHER")) {
        (payload.document = {
          type: model["document.type"],
          number: model["document.number"],
        }),
          (payload.dateOfBirth =
            model.dateOfBirth?.format("YYYY-MM-DD") ??
            dayjs().format("YYYY-MM-DD"));
        payload.gender = model.gender ?? "MALE";
      } else {
        payload.pesel = model.pesel.trim();
      }

      try {
        await http
          .post("/patient-portal/api/patient-profile", payload)
          .then(() => {
            notification.open({
              message: t(`REGISTRATION.SUCCESS`),
              class: "success",
            });
            setTimeout(() => {
              const loaclUrl = window.location.host;
              window.location.replace(loaclUrl);
              router.push({ path: "/" });
            }, 2000);
          });
      } catch (e: any) {
        each(e.response?.data, (fieldErrors) => {
          if (Array.isArray(fieldErrors)) {
            each(fieldErrors, (error) => {
              if (typeof error === "string") {
                notification.open({
                  message: error,
                  class: "error",
                });
              }
            });
          }
        });
      } finally {
        loading.value = false;
      }
    };

    const dateRange = (current: Dayjs) => {
      return (
        current &&
        (current >= dayjs() || current <= dayjs(new Date("1800-01-01")))
      );
    };

    watch(
      model,
      async () => {
        try {
          await registrationForm.validate();
          valid.value = true;
        } catch {
          valid.value = false;
        }
      },
      { deep: true }
    );

    const goBack = () => {
      router.back();
    };

    onMounted(async () => {
      await getLanguagesTypes();
      await getGenderTypes();
      await getDocumentTypes();
    });

    return {
      t,
      loading,
      kebabCase,
      submit,
      model,
      snakeCase,
      form,
      rules,
      registrationFormTemplate,
      valid,
      get,
      goBack,
      dateRange,
    };
  },
});

export default RegistrationComponent;
