import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ced23842"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile" }
const _hoisted_2 = { class: "white-box" }
const _hoisted_3 = { class: "white-box__value" }
const _hoisted_4 = { class: "white-box__value" }
const _hoisted_5 = { class: "white-box__value" }
const _hoisted_6 = { class: "white-box" }
const _hoisted_7 = {
  key: 0,
  class: "white-box__value"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 0,
  class: "white-box__value"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 0,
  class: "white-box__value"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_phone_number = _resolveComponent("phone-number")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.t("PROFILE.HEADER")), 1 /* TEXT */),
    _createVNode(_component_a_form, {
      ref: "formTemplate",
      model: _ctx.model,
      autocomplete: "off",
      rules: _ctx.rules
    }, {
      default: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.t("PROFILE.PATIENT_DATA.HEADER")), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_row, { class: "white-box__row profile__firstname" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                lg: 5,
                md: 5,
                sm: 12,
                xs: 12,
                class: "white-box__field-name"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("PROFILE.PATIENT_DATA.FIRST_NAME")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                lg: 18,
                md: 18,
                sm: 12,
                xs: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_skeleton, {
                    paragraph: false,
                    loading: _ctx.loadingUserProfile,
                    active: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.data.firstName), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_a_row, { class: "white-box__row profile__lastname" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                lg: 5,
                md: 5,
                sm: 12,
                xs: 12,
                class: "white-box__field-name"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("PROFILE.PATIENT_DATA.LAST_NAME")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                lg: 18,
                md: 18,
                sm: 12,
                xs: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_skeleton, {
                    paragraph: false,
                    loading: _ctx.loadingUserProfile,
                    active: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.data.lastName), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_a_row, { class: "white-box__row profile__pesel" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                lg: 5,
                md: 5,
                sm: 12,
                xs: 12,
                class: "white-box__field-name"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("PROFILE.PATIENT_DATA.ID")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                lg: 18,
                md: 18,
                sm: 12,
                xs: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_skeleton, {
                    paragraph: false,
                    loading: _ctx.loadingUserProfile,
                    active: ""
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.data.pesel), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("h2", null, _toDisplayString(_ctx.t("PROFILE.CONTACT_DATA.HEADER")), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_a_row, {
            class: "white-box__row profile__email",
            type: "flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                lg: { span: 6, order: 1 },
                md: { span: 6, order: 1 },
                sm: { span: 12, order: 1 },
                xs: { span: 24, order: 1 },
                class: "white-box__field-name"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("PROFILE.CONTACT_DATA.EMAIL")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                lg: { span: 12, order: 2 },
                md: { span: 12, order: 2 },
                sm: { span: 12, order: 3 },
                xs: { span: 24, order: 3 }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_skeleton, {
                    paragraph: false,
                    loading: _ctx.loadingUserProfile,
                    active: ""
                  }, {
                    default: _withCtx(() => [
                      (_ctx.toggleMode !== 'email')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.data.email), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      (_ctx.toggleMode === 'email')
                        ? (_openBlock(), _createBlock(_component_a_form_item, {
                            key: 1,
                            name: "email"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input, {
                                value: _ctx.model.email,
                                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.email) = $event))
                              }, null, 8 /* PROPS */, ["value"])
                            ]),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                lg: { span: 6, order: 3 },
                md: { span: 6, order: 3 },
                sm: { span: 12, order: 2 },
                xs: { span: 24, order: 3 },
                class: "white-box__actions"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_skeleton, {
                    title: { width: 90 },
                    paragraph: false,
                    loading: _ctx.loadingUserProfile,
                    active: ""
                  }, {
                    default: _withCtx(() => [
                      (_ctx.toggleMode !== 'email')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_a_button, {
                              class: "white-box__edit",
                              disabled: !_ctx.hasPermission(_ctx.actions.EDIT_EMAIL),
                              type: "link",
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.edit('email')))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_EditOutlined),
                                _createTextVNode(" " + _toDisplayString(_ctx.t("PROFILE.EDIT")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["disabled"])
                          ]))
                        : _createCommentVNode("v-if", true),
                      (_ctx.toggleMode === 'email')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createVNode(_component_a_button, {
                              type: "link",
                              class: "white-box__normal-action",
                              "html-type": "submit",
                              disabled: !_ctx.valid,
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateUser('email'))),
                              loading: _ctx.updatingUserProfile
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("PROFILE.SAVE")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["disabled", "loading"]),
                            _createVNode(_component_a_button, {
                              type: "link",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.cancel('email'))),
                              class: "white-box__red-action"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("PROFILE.CANCEL")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_a_row, {
            class: "white-box__row profile__phone-number-number",
            type: "flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                lg: { span: 6, order: 1 },
                md: { span: 6, order: 1 },
                sm: { span: 12, order: 1 },
                xs: { span: 24, order: 1 },
                class: "white-box__field-name"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("PROFILE.CONTACT_DATA.PHONE_NUMBER")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                lg: { span: 12, order: 2 },
                md: { span: 12, order: 2 },
                sm: { span: 12, order: 3 },
                xs: { span: 24, order: 3 }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_skeleton, {
                    paragraph: false,
                    loading: _ctx.loadingUserProfile,
                    active: ""
                  }, {
                    default: _withCtx(() => [
                      (_ctx.toggleMode !== 'phoneNumber')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.data.phoneNumber), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      _withDirectives(_createVNode(_component_a_form_item, { name: "phoneNumber" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_phone_number, {
                            placeholder: _ctx.t('PHONE_NUMBER.NUMBER'),
                            onChange: _ctx.onPhoneNumberChange,
                            value: _ctx.model.phoneNumber,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.phoneNumber) = $event))
                          }, null, 8 /* PROPS */, ["placeholder", "onChange", "value"])
                        ]),
                        _: 1 /* STABLE */
                      }, 512 /* NEED_PATCH */), [
                        [_vShow, _ctx.toggleMode === 'phoneNumber']
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                lg: { span: 6, order: 3 },
                md: { span: 6, order: 3 },
                sm: { span: 12, order: 2 },
                xs: { span: 24, order: 3 },
                class: "white-box__actions"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_skeleton, {
                    title: { width: 90 },
                    paragraph: false,
                    loading: _ctx.loadingUserProfile,
                    active: ""
                  }, {
                    default: _withCtx(() => [
                      (_ctx.toggleMode === 'phoneNumber')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createVNode(_component_a_button, {
                              type: "link",
                              class: "white-box__normal-action",
                              "html-type": "submit",
                              loading: _ctx.updatingUserProfile,
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateUser('phoneNumber'))),
                              disabled: !_ctx.valid
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("PROFILE.SAVE")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["loading", "disabled"]),
                            _createVNode(_component_a_button, {
                              type: "link",
                              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.cancel('phoneNumber'))),
                              class: "white-box__red-action"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("PROFILE.CANCEL")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]))
                        : _createCommentVNode("v-if", true),
                      (_ctx.toggleMode !== 'phoneNumber')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(_component_a_button, {
                              disabled: !_ctx.hasPermission(_ctx.actions.EDIT_PHONE_NUMBER),
                              class: "white-box__edit",
                              type: "link",
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.edit('phoneNumber')))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_EditOutlined),
                                _createTextVNode(" " + _toDisplayString(_ctx.t("PROFILE.EDIT")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["disabled"])
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_a_row, {
            class: "white-box__row profile__address address",
            type: "flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                lg: { span: 6, order: 1 },
                md: { span: 6, order: 1 },
                sm: { span: 12, order: 1 },
                xs: { span: 24, order: 1 },
                class: "white-box__field-name"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("PROFILE.CONTACT_DATA.ADDRESS")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                lg: { span: 12, order: 2 },
                md: { span: 12, order: 2 },
                sm: { span: 12, order: 3 },
                xs: { span: 24, order: 3 }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_skeleton, {
                    paragraph: false,
                    loading: _ctx.loadingUserProfile,
                    active: ""
                  }, {
                    default: _withCtx(() => [
                      (_ctx.toggleMode !== 'address')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.data.address.streetName) + " " + _toDisplayString(!_ctx.data.address.flatNumber
                    ? _ctx.data.address.streetNumber
                    : _ctx.data.address.streetNumber + "/" + _ctx.data.address.flatNumber) + ", " + _toDisplayString(_ctx.data.address.city) + " " + _toDisplayString(_ctx.data.address.postCode), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      (_ctx.toggleMode == 'address')
                        ? (_openBlock(), _createBlock(_component_a_row, {
                            key: 1,
                            gutter: [0, { xs: 16, sm: 16, md: 16 }]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                lg: { span: 8 },
                                md: { span: 8 },
                                sm: { span: 24 },
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "address.city" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        placeholder: "Miejscowość",
                                        value: _ctx.model.address.city,
                                        "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.address.city) = $event)),
                                        class: "address__city"
                                      }, null, 8 /* PROPS */, ["value"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_a_col, {
                                lg: { span: 6, offset: 1 },
                                md: { span: 6, offset: 1 },
                                sm: { span: 24 },
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "address.postCode" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        placeholder: "Kod pocztowy",
                                        value: _ctx.model.address.postCode,
                                        "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.address.postCode) = $event)),
                                        class: "address__post-code"
                                      }, null, 8 /* PROPS */, ["value"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_a_col, {
                                lg: { span: 8, offset: 1 },
                                md: { span: 8, offset: 1 },
                                sm: { span: 24 },
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "address.streetName" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        placeholder: "Ulica",
                                        value: _ctx.model.address.streetName,
                                        "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.address.streetName) = $event)),
                                        class: "address__street-name"
                                      }, null, 8 /* PROPS */, ["value"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_a_col, {
                                lg: { span: 11 },
                                md: { span: 11 },
                                sm: { span: 24 },
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "address.streetNumber" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        placeholder: "Nr domu",
                                        value: _ctx.model.address.streetNumber,
                                        "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.address.streetNumber) = $event)),
                                        class: "address__street-number"
                                      }, null, 8 /* PROPS */, ["value"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_a_col, {
                                lg: { span: 11, offset: 2 },
                                md: { span: 11, offset: 2 },
                                sm: { span: 24 },
                                xs: { span: 24 }
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "address.flatNumber" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        placeholder: "Nr mieszkania",
                                        value: _ctx.model.address.flatNumber,
                                        "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.address.flatNumber) = $event)),
                                        class: "address__flat-number"
                                      }, null, 8 /* PROPS */, ["value"])
                                    ]),
                                    _: 1 /* STABLE */
                                  })
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_a_col, {
                lg: { span: 6, order: 3 },
                md: { span: 6, order: 3 },
                sm: { span: 12, order: 2 },
                xs: { span: 24, order: 3 },
                class: "white-box__actions"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_skeleton, {
                    title: { width: 90 },
                    paragraph: false,
                    loading: _ctx.loadingUserProfile,
                    active: ""
                  }, {
                    default: _withCtx(() => [
                      (_ctx.toggleMode !== 'address')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createVNode(_component_a_button, {
                              class: "white-box__edit",
                              type: "link",
                              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.edit('address')))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_EditOutlined),
                                _createTextVNode(" " + _toDisplayString(_ctx.t("PROFILE.EDIT")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]))
                        : _createCommentVNode("v-if", true),
                      (_ctx.toggleMode === 'address')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createVNode(_component_a_button, {
                              type: "link",
                              class: "white-box__normal-action",
                              "html-type": "submit",
                              loading: _ctx.updatingUserProfile,
                              onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.updateUser('address'))),
                              disabled: !_ctx.valid
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("PROFILE.SAVE")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["loading", "disabled"]),
                            _createVNode(_component_a_button, {
                              type: "link",
                              onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.cancel('address'))),
                              class: "white-box__red-action"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("PROFILE.CANCEL")), 1 /* TEXT */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model", "rules"])
  ]))
}